import React from "react"
import { StaticQuery, Link } from "gatsby"

import styles from "../styles/header.module.scss"

export default () => (
  <StaticQuery
    query = {graphql`
      query HeaderQuery {
        allContentfulHomepage(limit:1) {
          edges {
            node {
              headerLogo {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <header className={styles.pageHeader}>
        <img
          className={styles.headerLogo}
          src={data.allContentfulHomepage.edges[0].node.headerLogo.file.url}
          alt="John Griffith Voiceover Services"
        />
        <nav className={styles.menu}>
          <ul>
            <li><Link to="/#demos">demos</Link></li>
            <li><Link to="/#about">about</Link></li>
            <li><Link to="/#contact">contact</Link></li>
          </ul>
        </nav>
      </header>
    )}
  />
)
