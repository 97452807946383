import React from "react"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import Demos from "../components/demos"
import About from "../components/about"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Header />
    <Hero />
    <Demos />
    <About />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
