import React from "react"
import { StaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from "../styles/contact.module.scss"

export default () => (
  <StaticQuery
    query = {graphql`
      query ContactQuery {
        allContentfulContact(limit:1) {
          edges {
            node {
              title
              body {
                json
              }
              image {
                fixed(width:1000,resizingBehavior:SCALE) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <section id="contact" className={styles.contact}>
        <div
          className={styles.image}
          style={{backgroundImage:`url(${data.allContentfulContact.edges[0].node.image.fixed.src})`}}
        ></div>
        <div className={styles.contactFormContainer}>
          <h2>{data.allContentfulContact.edges[0].node.title}</h2>
          <div className="introText">
            {documentToReactComponents(data.allContentfulContact.edges[0].node.body.json)}
          </div>
          <form className={styles.contactForm} name="contact" method="POST" action="https://formspree.io/john.griffith.creative@gmail.com">
            <label for="name">Name</label>
            <input type="text" name="name" id="name"/>
            <label for="_replyto">Email</label>
            <input type="text" name="_replyto" id="_replyto"/>
            <label for="message">Message</label>
            <textarea name="message" id="message"></textarea>
            <input type="submit" value="Submit" className={styles.submitButton}/>
          </form>
        </div>
      </section>
    )}
  />
)
