import React from "react"
import { StaticQuery } from "gatsby"

import styles from "../styles/hero.module.scss"
import arrow from "../assets/arrow-mobile-light-blue.svg"

export default () => (
  <StaticQuery
    query = {graphql`
      query HomepageQuery {
        allContentfulHomepage(limit:1) {
          edges {
            node {
              title
              subheading {
                subheading
              }
              heroImage {
                fixed(width:1200,resizingBehavior:SCALE) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <section
        className={styles.hero}
      >
        <div className={styles.heroText}>
          <h1>{data.allContentfulHomepage.edges[0].node.title}</h1>
        </div>
        <div className={styles.cta}>
          <span className={styles.ctaText}>{data.allContentfulHomepage.edges[0].node.subheading.subheading}</span>
          <div
            className={styles.ctaArrow}
            style={{backgroundImage:`url(${arrow})`}}
          ></div>
        </div>
        <div
          className={styles.heroImage}
          style={{backgroundImage:`url(${data.allContentfulHomepage.edges[0].node.heroImage.fixed.src})`}}
        ></div>
      </section>
    )}
  />
)
