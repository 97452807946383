import React from "react"
import { StaticQuery } from "gatsby"

import styles from "../styles/footer.module.scss"

export default () => (
  <StaticQuery
    query = {graphql`
      query FooterQuery {
        allContentfulHomepage(limit:1) {
          edges {
            node {
              footerLogo {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <footer className={styles.pageFooter}>
        <small>© {new Date().getFullYear()} John Griffith</small>
        <img
          className={styles.footerLogo}
          src={data.allContentfulHomepage.edges[0].node.footerLogo.file.url}
          alt="John Griffith Voiceover"
        />
      </footer>
    )}
  />
)
