import React from "react"
import { StaticQuery } from "gatsby"

import styles from "../styles/demos.module.scss"

export default () => (
  <StaticQuery
    query = {graphql`
      query demoQuery {
        allContentfulDemo {
          edges {
            node {
              title
              tags
              audio {
                file {
                  contentType
                  url
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <section id="demos" className={styles.demos}>
        <h2>Listen to my demos</h2>
        <ul className={styles.demoList}>
        {data.allContentfulDemo.edges.map((demo) => {
          return(
            <li className={styles.demoItem}>
              <div className={styles.demoContainer}>
                <h3>{demo.node.title}</h3>
                <audio controls>
                  <source src={demo.node.audio.file.url} type={demo.node.audio.contentType}/>
                </audio>
              </div>
            </li>
          )
        })}
        </ul>
      </section>
    )}
  />
)
