import React from "react"
import { StaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from "../styles/about.module.scss"

export default () => (
  <StaticQuery
    query = {graphql`
      query AboutQuery {
        allContentfulAboutSection {
          edges {
            node {
              title
              body {
                json
              }
              icon {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render = {data => (
      <section id="about" className={styles.aboutSection}>
      {data.allContentfulAboutSection.edges.map(section => {
        return(
          <div key={section.node.title} className={styles.section}>
            <img alt={section.node.title} src={section.node.icon.file.url}/>
            <div className={styles.titleContainer}>
              <h2>{section.node.title}</h2>
            </div>
            <div className={styles.bodyText}>
              {documentToReactComponents(section.node.body.json)}
            </div>
          </div>
        )
      })}
      </section>
    )}
  />
)
